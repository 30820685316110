export default () => ({
  selectedTab: null,
  query: {
    data: null,
    error: null,
    loading: true,
    variables: {},
  },
  filterItems: {
    divisions: [{ label: "Loading", value: "" }],
    companies: [{ label: "Loading", value: "" }],
    jobs: [{ label: "Loading", value: "" }],
    locations: [{ label: "Loading", value: "" }],
    countries: [{ label: "Loading", value: "" }],
    teams: [{ label: "Loading", value: "" }],
    teamTypes: [{ label: "Loading", value: "" }],
  },
  filterItemSearch: {
    key: "",
    searchTerms: [],
  },
});
