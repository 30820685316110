import React from "react";
import PropTypes from "prop-types";

import WorkspaceErrorBoundary from "src/components/WorkspaceErrorBoundary";
import ErrorReportingConfiguration from "src/services/errorReporting/ErrorReportingConfiguration";
import { ApolloSettingsContextProvider } from "src/contexts/global/ApolloSettingsContext";
import { GlobalContextProvider } from "src/contexts/global/GlobalContext";
import { GuideTourContextProvider } from "src/contexts/global/GuidedTourContext";
import { FeatureFlagsContextProvider } from "src/contexts/global/FeatureFlagsContext";
import { WorkspaceContextProvider } from "src/contexts/global/WorkspaceContext";
import WorkspaceChallenge from "src/contexts/global/WorkspaceContext/WorkspaceChallenge";
import FilterAndFteContextProvider from "src/contexts/filterAndFte/FilterAndFteContextProvider";
import { PageRefreshContextProvider } from "src/contexts/pageRefresh/PageRefreshContext";
import useSentryReplayHook from "src/services/errorReporting/useSentryReplayHook";
import { ModalProvider } from "src/contexts/withModal";
import ValueGraphInsightsProvider from "src/contexts/valueGraphInsights/ValueGraphInsightsProvider";
import AppLayout from "../layouts/AppLayout";
import { VisualisationProvider } from "../contexts/visualisationContext";
import { NotFoundErrorBoundary } from "../components/ErrorBoundary";
import TenantApolloProvider from "./TenantApolloProvider";
import Routes from "./Routes";
import AppHelmet from "./Helmet";

const AuthenticatedApp = ({ getTokenSilently, user, themeSwitcher }) => {
  useSentryReplayHook();

  return (
    <ApolloSettingsContextProvider
      user={user}
      getTokenSilently={getTokenSilently}
    >
      <TenantApolloProvider>
        <WorkspaceErrorBoundary user={user}>
          <FeatureFlagsContextProvider user={user}>
            <WorkspaceContextProvider user={user}>
              <GlobalContextProvider user={user}>
                <WorkspaceChallenge>
                  <PageRefreshContextProvider>
                    <GuideTourContextProvider>
                      <ErrorReportingConfiguration>
                        <AppHelmet>
                          <ValueGraphInsightsProvider>
                            <FilterAndFteContextProvider>
                              <VisualisationProvider>
                                <AppLayout themeSwitcher={themeSwitcher}>
                                  <NotFoundErrorBoundary>
                                    <ModalProvider>
                                      <Routes
                                        user={user}
                                        themeSwitcher={themeSwitcher}
                                      />
                                    </ModalProvider>
                                  </NotFoundErrorBoundary>
                                </AppLayout>
                              </VisualisationProvider>
                            </FilterAndFteContextProvider>
                          </ValueGraphInsightsProvider>
                        </AppHelmet>
                      </ErrorReportingConfiguration>
                    </GuideTourContextProvider>
                  </PageRefreshContextProvider>
                </WorkspaceChallenge>
              </GlobalContextProvider>
            </WorkspaceContextProvider>
          </FeatureFlagsContextProvider>
        </WorkspaceErrorBoundary>
      </TenantApolloProvider>
    </ApolloSettingsContextProvider>
  );
};

AuthenticatedApp.propTypes = {
  getTokenSilently: PropTypes.func,
  user: PropTypes.object.isRequired,
  themeSwitcher: PropTypes.func,
};

export default AuthenticatedApp;
