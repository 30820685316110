import React from "react";
import PropTypes from "prop-types";
import { Small, StyledLink } from "orcs-design-system";

const OpenPageLink = ({ label, description, path }) => {
  return (
    <>
      <StyledLink to={path} mb="xs">
        {label}
      </StyledLink>
      <Small display="block" color="greyDark">
        {description}
      </Small>
    </>
  );
};

OpenPageLink.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
};

export default OpenPageLink;
