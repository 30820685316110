import { isEmpty, map, find } from "lodash";

const getOrderedList = ({ insightsOrder, data }) => {
  if (isEmpty(insightsOrder)) {
    return data;
  }
  return map(insightsOrder, (type) =>
    find(data, (insight) => insight?.type === type)
  );
};

export default getOrderedList;
