import React from "react";
import PropTypes from "prop-types";

import Auth0UserProps from "src/custom-prop-types/auth0user";

import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";
import NetworkError from "./NetworkError";
import GenericError from "./GenericError";
import ForbiddenError from "./ForbiddenError";
import WorkspaceNotFoundError from "./WorkspaceNotFoundError";
import AuthProviderError from "./AuthProviderError";
import UnauthorisedRoleError from "./UnauthorisedRoleError";
import GroupAccessUnauthorizedError from "./GroupAccessUnauthorizedError";
import PITNotFoundError from "./PITNotFoundError";
import MaintenancePage from "./MaintenancePage";
import NotFoundError from "./NotFoundError";
import ServerError from "./ServerError";

import {
  isAuth0Error,
  isUnauthorisedRoleError,
  isGraphQLUnauthorizedError,
  isForbiddenError,
  isNetworkError,
  isPITNotFound,
  isUnderMaintenance,
  isWorkspaceNotFoundUIError,
  isWorkspaceNotFoundError,
  isWorkspaceAccessDeniedError,
  isNotFoundError,
  isServerError,
} from "./detectErrorType";

const ErrorMessage = ({
  user,
  error,
  clientInfo,
  showHeader,
  showContainer,
}) => {
  if (isAuth0Error(error)) {
    trackEvent(EVENT_TRACKING.USER_ERROR, {
      error: "auth0_error",
    });
    return <AuthProviderError errorContext={error} />;
  }

  if (isUnauthorisedRoleError(error)) {
    trackEvent(EVENT_TRACKING.USER_ERROR, {
      error: "unauthorised_role_error",
    });
    return <UnauthorisedRoleError clientInfo={clientInfo} />;
  }

  if (isGraphQLUnauthorizedError(error)) {
    trackEvent(EVENT_TRACKING.USER_ERROR, {
      error: "graphql_unauthorized_error",
    });
    return <GroupAccessUnauthorizedError clientInfo={clientInfo} />;
  }

  if (isUnderMaintenance(error)) {
    trackEvent(EVENT_TRACKING.USER_ERROR, {
      error: "under_maintenance",
    });
    return <MaintenancePage />;
  }

  if (isForbiddenError(error)) {
    trackEvent(EVENT_TRACKING.USER_ERROR, {
      error: "forbidden_error",
    });
    return <ForbiddenError clientInfo={clientInfo} />;
  }

  if (isNetworkError(error)) {
    trackEvent(EVENT_TRACKING.USER_ERROR, {
      error: "network_error",
    });
    return <NetworkError clientInfo={clientInfo} />;
  }

  if (
    isWorkspaceNotFoundUIError(error) ||
    isWorkspaceNotFoundError(error) ||
    isWorkspaceAccessDeniedError(error)
  ) {
    trackEvent(EVENT_TRACKING.USER_ERROR, {
      error: "workspace_not_found_error",
    });
    return (
      <WorkspaceNotFoundError
        user={user}
        clientInfo={clientInfo}
        showHeader={showHeader}
        showContainer={showContainer}
      />
    );
  }

  if (isPITNotFound(error)) {
    trackEvent(EVENT_TRACKING.USER_ERROR, {
      error: "pit_not_found_error",
    });
    return <PITNotFoundError clientInfo={clientInfo} />;
  }

  if (isNotFoundError(error)) {
    trackEvent(EVENT_TRACKING.USER_ERROR, {
      error: "not_found_error",
    });
    return <NotFoundError />;
  }

  if (isServerError(error)) {
    trackEvent(EVENT_TRACKING.USER_ERROR, {
      error: "server_error",
    });
    return <ServerError />;
  }

  trackEvent(EVENT_TRACKING.USER_ERROR, {
    error: "generic_error",
  });
  return (
    <GenericError
      clientInfo={clientInfo}
      showHeader={showHeader}
      showContainer={showContainer}
    />
  );
};

ErrorMessage.propTypes = {
  user: Auth0UserProps,
  error: PropTypes.object.isRequired,
  clientInfo: PropTypes.string,
  showHeader: PropTypes.bool,
  showContainer: PropTypes.bool,
};

export default ErrorMessage;
