import { useMemo, useReducer } from "react";
import { isStrategyPage } from "src/util/url";
import { useFeatureFlag } from "src/contexts/global/FeatureFlagsContext";
import {
  strategyInsightsReducer,
  strategyInsightInitialState,
} from "../reducer";
import getOrderedList from "../utils/getOrderedList";
import useInsightsData from "./useInsightsData";

const useStrategyInsights = (match) => {
  const teamId = match?.params?.id;
  const isFlagEnalbled = useFeatureFlag("strategy-tab-insights", false);

  const { data } = useInsightsData({
    teamId,
    skip: !isStrategyPage(match?.params) || !teamId || !isFlagEnalbled,
  });
  const [state, dispatch] = useReducer(
    strategyInsightsReducer,
    useMemo(() => strategyInsightInitialState, [])
  );

  const orderedInsights = useMemo(() => {
    return getOrderedList({ insightsOrder: state?.insightsOrder, data });
  }, [data, state?.insightsOrder]);

  return {
    strategyInsights: orderedInsights,
    insightsDispatch: dispatch,
    state,
  };
};

export default useStrategyInsights;
