import React from "react";
import { Spacer, Box, Flex, Typography, Icon, Badge } from "orcs-design-system";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import GroupHierarchyBreadcrumb from "src/components/GroupHierarchyBreadcrumb";
import createActionsMenuHandler from "../util/actionsMenuUtil";

const Component = ({ group = {}, groupType } = {}) => {
  const [openActionsMenu, closeActionsMenu] = createActionsMenuHandler(
    ".guided-tour-team-details-view-actions-menu"
  );
  return {
    triggerInSidebar: true,
    autoStart: false,
    showProgress: false,
    showSkipButton: true,
    hideBackButton: true,
    dependsOn: [],
    steps: [
      {
        target: ".guided-tour-team-details-welcome",
        placement: "center",
        content: (
          <Spacer my={3}>
            <Flex alignItems="center" justifyContent="center">
              <Spacer mx="3">
                <Typography.H3 weight="bold" textAlign="center">
                  Welcome to your
                </Typography.H3>
                <GroupTypeBadge group={group} />
              </Spacer>
            </Flex>
            <Typography.P textAlign="center">
              Let me show you around.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-team-details-team-name",
        content: (
          <Spacer my={3}>
            <Flex alignItems="center">
              <Spacer>
                <Typography.H4 textAlign="left">
                  This is the <strong>Name</strong> of your{" "}
                </Typography.H4>
                <Box mx={3}>
                  <GroupTypeBadge group={group} />
                </Box>
              </Spacer>
            </Flex>
            <Typography.P display="block" textAlign="left">
              The <strong>Name</strong> helps with searching and identifying
              groups within TeamForm.
            </Typography.P>
            <Typography.P display="block" textAlign="left">
              Sometimes a simple name is not enough to identify what you&apos;re
              working with. This is where <strong>Type</strong> and{" "}
              <strong>Hierarchy</strong> can help give a fuller picture. They
              are covered next.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-team-details-team-type",
        content: (
          <Spacer my={3}>
            <Flex alignItems="center">
              <Spacer>
                <Typography.H4 display="block" textAlign="left">
                  The{" "}
                </Typography.H4>
                <Box mx={3}>
                  <GroupTypeBadge group={group} />
                </Box>
                <Typography.H4 display="block" textAlign="left">
                  <strong>Type</strong>
                </Typography.H4>
              </Spacer>
            </Flex>
            {groupType && !isEmpty(groupType.shortDesc) && (
              <Typography.Quote display="block" textAlign="left">
                {groupType.shortDesc}
              </Typography.Quote>
            )}
            <Typography.P display="block" textAlign="left">
              This <strong>Type</strong> determines what we can see and do
              within this page, the parent-child relationships with other
              groups, and the kind of principles we should apply.
            </Typography.P>
            <Typography.P display="block" textAlign="left">
              Try mouse-over this badge at the end of this tour to get more info
              in the useful tool-tip.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-team-details-member-count",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              The <strong>Member Count</strong>
            </Typography.H4>
            <Typography.P display="block" textAlign="left">
              The <strong>Member Count</strong> is always the single count of
              individuals. It will only count individuals once, even if multiple
              memberships are detected.
            </Typography.P>
            <Typography.P display="block" textAlign="left">
              When viewed here, it&apos;s the <strong>Unique</strong> total of
              all the <strong>Direct</strong> members of the {group.type} and{" "}
              <strong>In-direct</strong> members of all child groups of the{" "}
              {group.type}.
            </Typography.P>
            <Typography.P display="block" textAlign="left">
              In other places it might mean something slightly different, so
              again try mouse-over this badge at the end of this tour to get
              more info.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-team-details-fte",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              This is the <strong>FTE</strong> total.
            </Typography.H4>
            <Typography.P display="block" textAlign="left">
              It&apos;s the total <strong>FTE</strong> for members within this{" "}
              {group.type}. It will not be shown for every group, only your{" "}
              <Badge variant="secondary">Supply</Badge> and{" "}
              <Badge variant="success">Demand</Badge> types.
            </Typography.P>
            <Typography.P display="block" textAlign="left">
              Try mouse-over this badge at the end of this tour to get more info
              in the useful tool-tip.
            </Typography.P>
            <Typography.P display="block" textAlign="left">
              On mouse-over:
              <ul>
                <li>
                  When <strong>Providing FTE</strong> is displayed: it&apos;s
                  focusing on each members FTE capacity.
                </li>
                <li>
                  When <strong>Assigned FTE</strong> is displayed, it&apos;s
                  focusing on each members assigned FTE.
                </li>
              </ul>
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-team-details-hierarchy",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              This is the <strong>Hierarchy</strong>
            </Typography.H4>

            <Typography.P display="block" textAlign="left">
              You can click the links here for easy access to parent groups up
              the <strong>Hierarchy.</strong>
            </Typography.P>

            <Typography.P display="block" textAlign="left">
              A <strong>Hierarchy</strong> must be always be{" "}
              <strong>Unique.</strong>
            </Typography.P>

            <Typography.P display="block" textAlign="left">
              It can be shown in many places within TeamForm with the{" "}
              <Icon icon={["far", "layer-group"]} size="lg" /> icon, as a
              compact way for you to mouse-over to read the full hierarchy in a
              tool-tip.
            </Typography.P>

            <Typography.P display="block" textAlign="left">
              For example your {group.type} will look this :
              <Box mx={3}>
                <GroupHierarchyBreadcrumb group={group} />
              </Box>
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-team-details-view-in-summary",
        beforeAction: openActionsMenu,
        afterAction: closeActionsMenu,
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">Summary Button</Typography.H4>
            <Typography.P display="block" textAlign="left">
              Click the <strong>View in Insights</strong> button to show your{" "}
              {group.type} within the <strong>Insights</strong> page.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-team-details-explore-in-org",
        beforeAction: openActionsMenu,
        afterAction: closeActionsMenu,
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">Explore Button</Typography.H4>
            <Typography.P display="block" textAlign="left">
              Similarly, click the <strong>Explore in Organisation</strong>{" "}
              button to show your {group.type} within the{" "}
              <strong>Explore</strong> page.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-team-details-tab",
        placement: "top",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">Details tab</Typography.H4>
            <Typography.P display="block" textAlign="left">
              Currently, you are viewing the <strong>Details</strong> tab. It
              gives you a quick summary of the <strong>Direct members</strong>{" "}
              and <strong>Groups</strong> within this {group.type}.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-team-details-direct-members",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              These are the direct members
            </Typography.H4>
            <Typography.P lineHeight={1} display="block" textAlign="left">
              It&apos;s a list of all people with a <strong>Direct</strong>{" "}
              membership to this {group.type}.
            </Typography.P>
            <Typography.P lineHeight={1} display="block" textAlign="left">
              The <strong>FTE</strong> values are shown for each member to
              indicate how much of there time is dedicated here.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-team-details-sub-groups",
        content: (
          <Spacer my={3}>
            <Typography.H4 display="block" textAlign="left">
              These are the <strong>Groups</strong>
            </Typography.H4>
            <Typography.P display="block" textAlign="left">
              They are the direct groups of your {group.type}.
            </Typography.P>

            <Typography.P display="block" textAlign="left">
              It will show the <strong>Principle</strong> dots (if any are
              applied), the <strong>Type</strong>, and the{" "}
              <strong>Member Count</strong> per group. Try mouse-over any of
              these for get more info.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-team-details-welcome",
        placement: "center",
        content: (
          <Spacer my={3}>
            <Typography.H3 weight="bold" textAlign="center">
              That&apos;s it!
            </Typography.H3>
            <Typography.P textAlign="center">
              Try exploring more of the application by clicking some of the
              other tabs and buttons.
            </Typography.P>
            <Typography.P textAlign="center">Have fun!</Typography.P>
            <Typography.Small textAlign="left">
              We love feedback, please leave any comments or suggestions in the
              help button below.
            </Typography.Small>
          </Spacer>
        ),
      },
    ],
  };
};

Component.propTypes = {
  group: PropTypes.object,
  groupType: PropTypes.object,
};

export default Component;
