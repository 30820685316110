import { DIRECTORY_ROLE, POWER_ROLE, ADMIN_ROLE } from "src/services/auth";
import useUserRole from "./useUserRole";

const useRestricted = () => {
  const userRole = useUserRole();
  return [
    userRole === DIRECTORY_ROLE,
    userRole === POWER_ROLE,
    userRole === ADMIN_ROLE,
  ];
};

export default useRestricted;
