import { map } from "lodash";
import { Select } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import SelectComponents from "src/components/SelectComponents";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import getInputLabel from "src/util/getInputLabel";

import FormatOptionLabel from "./FormatOptionLabel";

const noOptions = () => "No search results";

const TeamDropdown = ({
  id,
  label,
  height,
  placeholder,
  isSearchable,
  isLoading,
  options,
  loadOptions,
  onSelectTeam,
  value,
  noOptionsMessage = noOptions,
  isDisabled,
  isClearable,
  groupTypes,
  icon,
  formatOptionLabel,
  invalid,
  mandatory,
  focus,
  cacheOptions = true,
}) => {
  const loadOptionsCallback = (term, callback) => {
    loadOptions(term, (opts) => {
      const newOpts = map(opts, (opt) => {
        return {
          ...opt,
          groupTypes,
        };
      });
      callback(newOpts);
    });
  };

  const formatOptionLabelFn =
    formatOptionLabel ||
    ((data, event) => {
      return FormatOptionLabel({ ...data, icon }, event);
    });

  return (
    <Select
      inputId={id}
      label={getInputLabel(label, invalid)}
      ariaLabel={placeholder || "Search and select team"}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      cacheOptions={cacheOptions}
      backspaceRemovesValue={true}
      isClearable={isClearable}
      isSearchable={isSearchable}
      isLoading={isLoading}
      defaultOptions={options}
      loadOptions={loadOptionsCallback}
      onChange={onSelectTeam}
      formatOptionLabel={formatOptionLabelFn}
      value={value}
      menuPlacement="auto"
      isDisabled={isDisabled}
      components={SelectComponents}
      selectType="async"
      height={height}
      invalid={invalid}
      mandatory={mandatory}
      focus={focus}
    />
  );
};

TeamDropdown.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  formatOptionLabel: PropTypes.func,
  height: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  isSearchable: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  loadOptions: PropTypes.func,
  onSelectTeam: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  groupTypes: GroupTypesPropType,
  invalid: PropTypes.bool,
  mandatory: PropTypes.bool,
  focus: PropTypes.bool,
  cacheOptions: PropTypes.bool,
};

export default TeamDropdown;
