import { get } from "lodash";

import { auth0UserMetadataKey } from "src/services/auth";

export const uiShaShort = () => {
  const gitSha =
    process.env.REACT_APP_COMMIT_REF || process.env.REACT_APP_GIT_SHA;
  return gitSha ? gitSha.slice(0, 7) : null;
};

export const uiShaLong = () => {
  const gitSha =
    process.env.REACT_APP_COMMIT_REF || process.env.REACT_APP_GIT_SHA;
  return gitSha;
};

export const uiBuild = () => {
  return process.env.REACT_APP_REVIEW_ID || process.env.REACT_APP_BUILD_NUMBER;
};

export const uiVersion = () => {
  const sha = uiShaShort();
  const build = uiBuild();
  return sha || build ? `${sha} [${build}]` : null;
};

export const tenantId = (user) =>
  user ? get(user[auth0UserMetadataKey], "tenantId") : null;
