import React, { useMemo, useReducer } from "react";
import PropTypes from "prop-types";
import { isNil } from "lodash";
import { useQuery } from "@apollo/client";

import Auth0UserProps from "src/custom-prop-types/auth0user";
import { createAuth } from "src/services/auth";
import useRestricted from "src/app/Restricted/useRestricted";

import { getContext as getContextQuery } from "../context.graphql";
import { Provider } from "./GlobalContext";

export const GLOBAL_STATE_ACTIONS = {
  UPDATE_FEATURE_FLAGS: "globalConfig.featureFlags",
  UPDATE_GLOBAL_STATE: "globalState",
};

const globalStateReducer = (state, action) => {
  switch (action.type) {
    case GLOBAL_STATE_ACTIONS.UPDATE_FEATURE_FLAGS:
      return {
        ...state,
        globalConfig: {
          ...state.globalConfig,
          config: {
            ...state.globalConfig.config,
            featureFlags: action.data,
          },
        },
      };

    case GLOBAL_STATE_ACTIONS.UPDATE_GLOBAL_STATE:
      return { ...state, ...action.data, updated: true };
    default:
      return state;
  }
};

const GlobalContextProvider = (props) => {
  const { children, user } = props;

  const auth = useMemo(() => createAuth(user), [user]);
  const [isRestricted] = useRestricted();

  const [globalState, dispatch] = useReducer(globalStateReducer, {
    auth,
  });

  const {
    data: contextData = {},
    loading: loadingContext,
    error,
  } = useQuery(getContextQuery, {
    variables: { isRestricted },
    onCompleted(data) {
      dispatch({
        type: GLOBAL_STATE_ACTIONS.UPDATE_GLOBAL_STATE,
        data,
      });
    },
  });

  const {
    allocationProject,
    oldestPersonIndexDate,
    version,
    userPerson,
    maintenanceMode,
    globalConfig,
    importDate,
    updated: stateUpdated = false,
  } = globalState;

  // waiting on state to be updated before considering context ready so that the userPerson is available before routing logic kicks in.
  // adding a condition for error to stop showing an infinite loading spinner when there is an error.
  const isContextReady = useMemo(() => {
    if (error) {
      return !loadingContext && !isNil(contextData);
    }
    return !loadingContext && !isNil(contextData) && stateUpdated;
  }, [loadingContext, contextData, error, stateUpdated]);

  return (
    <Provider
      auth={auth}
      error={error}
      allocationProject={allocationProject}
      oldestPersonIndexDate={oldestPersonIndexDate}
      version={version}
      userPerson={userPerson}
      isContextReady={isContextReady}
      maintenanceMode={maintenanceMode}
      globalConfig={globalConfig}
      importDate={importDate}
      dispatch={dispatch}
    >
      {children}
    </Provider>
  );
};

GlobalContextProvider.propTypes = {
  user: Auth0UserProps,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default GlobalContextProvider;
