import { useAuth0 } from "src/contexts/auth0Provider";
import {
  hasAdminRole,
  hasPowerRole,
  hasDirectoryRole,
  DIRECTORY_ROLE,
  POWER_ROLE,
  ADMIN_ROLE,
  UNKNOWN_USER,
} from "src/services/auth";

const useUserRole = () => {
  const auth = useAuth0();

  if (!auth?.user) {
    return UNKNOWN_USER;
  }

  return hasAdminRole(auth.user)
    ? ADMIN_ROLE
    : hasPowerRole(auth.user)
    ? POWER_ROLE
    : hasDirectoryRole(auth.user)
    ? DIRECTORY_ROLE
    : UNKNOWN_USER;
};

export default useUserRole;
