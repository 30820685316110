import React from "react";
import PropTypes from "prop-types";
import { isEmpty, map } from "lodash";
import {
  Box,
  Flex,
  FlexItem,
  H3,
  H5,
  Divider,
  Icon,
  P,
  StyledLink,
  Text,
} from "orcs-design-system";
import styled from "styled-components";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import ExpandableContent from "src/components/ExpandableContent";
import RichTextContent from "src/components/RichTextContent";

const ReleaseNotesContainer = styled(Box)`
  border-bottom: none;
  border-right: none;
  border-left: none;
  flex: 0 0 auto;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const WorkspaceAnnouncements = ({ inSideNav }) => {
  const workspace = useWorkspace();

  const { panelHeading, notifications } =
    workspace?.config?.notifications?.landingPageNotifications || {};

  const heading = panelHeading || "Announcements";

  let panelBody;

  if (isEmpty(notifications)) {
    panelBody = (
      <P color="greyDark" p={inSideNav ? "0" : "r"} pt="r">
        There are currently no {heading.toLowerCase()}.
      </P>
    );
  } else {
    panelBody = map(notifications, ({ id, content }, index) => {
      return (
        <Box key={id}>
          <Box p={inSideNav ? "0" : "r"} pt={inSideNav ? "s" : "r"}>
            <ExpandableContent>
              <RichTextContent
                content={content}
                fontSize={inSideNav ? "1.4rem" : "1.6rem"}
              />
            </ExpandableContent>
          </Box>
          {index < notifications.length - 1 && (
            <Divider light mt={inSideNav ? "s" : "0"} />
          )}
        </Box>
      );
    });
  }

  const AnnouncementsWrapper = ({ children }) => {
    if (inSideNav) {
      return <Box height="calc(100% - 25px)">{children}</Box>;
    }
    return (
      <Box bg="white" boxBorder="default" shadow="default" borderRadius={2}>
        {children}
      </Box>
    );
  };

  AnnouncementsWrapper.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <AnnouncementsWrapper>
      {inSideNav ? (
        <H5 fontWeight="bold" mb="r">
          {heading}
        </H5>
      ) : (
        <H3 p="r">{heading}</H3>
      )}
      <Divider light />
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        height={inSideNav ? "100%" : "auto"}
      >
        <FlexItem flex="1 1 auto" overflowY="auto" pb="s">
          {panelBody}
        </FlexItem>
        <ReleaseNotesContainer
          boxBorder="default"
          p={inSideNav ? "s" : "r"}
          pb={inSideNav ? "s" : "r"}
          bg="white"
          borderRadius={inSideNav ? "0" : 2}
        >
          <Flex alignItems="center" justifyContent="center">
            <Text fontSize="2rem">&#128640;</Text>
            <StyledLink
              ml="s"
              fontSize={inSideNav ? "1" : "2"}
              fontWeight="bold"
              display="block"
              href="https://get.help.teamform.co/servicedesk/customer/portal/2/article/2770240033"
              target="_blank"
            >
              TeamForm release notes
              <Icon
                ml="xs"
                size="sm"
                transform="up-2"
                icon={["fas", "external-link-alt"]}
                title="Open in new window"
              />
            </StyledLink>
          </Flex>
        </ReleaseNotesContainer>
      </Flex>
    </AnnouncementsWrapper>
  );
};

WorkspaceAnnouncements.propTypes = {
  inSideNav: PropTypes.bool,
};

export default WorkspaceAnnouncements;
