import { isEmpty } from "lodash";
import { SideNav, Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTheme } from "styled-components";

import useSideNavClickListener from "src/hooks/useSideNavClickListener";
import useWindowResizeListener from "src/hooks/useWindowResizeListener";

import { useWorkspaceTagConfig } from "src/contexts/global/WorkspaceContext";
import { useAuth0 } from "src/contexts/auth0Provider";
import { useFeatureFlags } from "src/contexts/global/FeatureFlagsContext";
import useSideNavItemBuilder from "src/sidebar/useSideNavItemBuilder";
import eventEmitter from "src/services/eventEmitter";
import resetAppHeight from "./libs/resetAppHeight";
import useFilterNavItems from "./hooks/useFilterNavItems";

const sideNavHeight = "calc(100vh - 88px)";

const SideNavigation = ({ themeSwitcher }) => {
  const isDemo = !!window?.location?.search?.includes("demo=true");
  const theme = useTheme();
  const [clickedItem, setClickedItem] = useState({});
  const tagTypesConfig = useWorkspaceTagConfig();
  const { user, logout } = useAuth0();
  const featureFlags = useFeatureFlags();
  const navItems = useSideNavItemBuilder({
    themeSwitcher,
    clickedItem,
    user,
    tagTypesConfig,
    logout,
    featureFlags,
    eventEmitter,
    setClickedItem,
  });
  useWindowResizeListener(resetAppHeight, isDemo, theme);
  useSideNavClickListener();

  const fileredNavItems = useFilterNavItems(navItems);

  if (isEmpty(fileredNavItems)) {
    return null;
  }

  return (
    <Box py={["0", "0", "0", "r"]} pl={["0", "0", "0", "r"]} bg="greyLightest">
      <SideNav items={fileredNavItems} sideNavHeight={sideNavHeight} />
    </Box>
  );
};

SideNavigation.propTypes = {
  themeSwitcher: PropTypes.func,
};

export default SideNavigation;
