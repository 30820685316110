import { useApolloClient, useQuery } from "@apollo/client";
import { size, debounce, map } from "lodash";
import { useRef, useState } from "react";

import { pagedSearchTags } from "src/queries/tags.graphql";
import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";
import { TAG_SEARCH_LIMIT } from "src/consts/tags";

const usePagedTagSearch = ({ tagTypes }) => {
  const client = useApolloClient();
  const [totalTagCount, setTotalTagCount] = useState({});
  const initialTotalTagCount = useRef({});
  const { data, loading, error } = useQuery(pagedSearchTags, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: {
          tagTypes,
        },
        limit: TAG_SEARCH_LIMIT,
      },
    },
    onCompleted: (response) => {
      initialTotalTagCount.current = {
        total: response?.results?.totalCount,
        currentSize: size(response?.results?.tags),
      };
      setTotalTagCount({
        total: response?.results?.totalCount,
        currentSize: size(response?.results?.tags),
      });
    },
  });

  const resetTotalTagCount = () => {
    if (initialTotalTagCount?.current) {
      setTotalTagCount(initialTotalTagCount.current);
    }
  };

  const loadOptions = debounce((value, callback) => {
    client
      .query({
        query: pagedSearchTags,
        variables: {
          input: {
            filter: {
              searchTerm: value,
              tagTypes,
            },
            limit: TAG_SEARCH_LIMIT,
          },
        },
      })
      .then((result) => {
        callback(
          map(result?.data?.results?.tags, (tag) => ({
            label: tag.value,
            value: tag.id,
          }))
        );
        setTotalTagCount({
          total: result?.data?.results?.totalCount,
          currentSize: size(result?.data?.results?.tags),
        });
      });
  }, DEBOUNCE_SEARCH_TIME);

  return {
    data,
    error,
    loading,
    loadOptions,
    totalTagCount,
    resetTotalTagCount,
  };
};

export default usePagedTagSearch;
