import { generatePath, matchPath } from "react-router";
import { filter, values, includes, replace } from "lodash";

const URL = {
  SUMMARY: "/:workspaceId?/summary",
  SEARCH: "/:workspaceId?/search/:type/:searchTerm?",
  PEOPLE: "/:workspaceId?/people",
  EXPLORE: "/:workspaceId?/explore",
  USER_HOME_BASE_PATH: "/:workspaceId?/user",
  USER_HOME: "/:workspaceId?/user/:tabType",
  VISUALISATION: "/:workspaceId?/visualisation",
  VISUALISATION_WITH_ID: "/:workspaceId?/visualisation/:pageType/:aggregateId",
  TEAMS: "/:workspaceId?/teams",
  TEAM_DETAILS_BASE_PATH: "/:workspaceId?/teams/:id",
  TEAM_DETAILS: "/:workspaceId?/teams/:id/:tabType",
  PERSON_DETAILS: "/:workspaceId?/people/:id/:tabType",
  PERSON_DETAILS_BASE_PATH: "/:workspaceId?/people/:id",
  PERSON_DETAILS_FROM_DIRECTORY: "/:workspaceId?/people/:id",
  DATASOURCE: "/:workspaceId?/configuration/datasource/:dataSourceId",
  WORKSPACES: "/:workspaceId?/workspaces",
  WORKSPACE_CONFIGURATION:
    "/:workspaceId?/configuration/:tabType/:actionType?/:integrationType?",
  CREATE_WORKSPACE: "/:workspaceId?/workspaces/create",
  LOGOUT: "/logout",
  INTERNAL_DASHBOARD_GENERAL: "/:workspaceId?/internal-dashboard/general",
  INTERNAL_DASHBOARD: "/:workspaceId?/internal-dashboard/:tabType",
  USER_REPORT: "/:workspaceId?/user-report",
  REPORTS: process.env.REACT_APP_REPORTS_DOMAIN,
  DIRECTORY_USER_HOME: "/:workspaceId?/home",
  ADMIN: "/:workspaceId?/admin/dashboard",
  ADMIN_DASHBOARD: "/:workspaceId?/admin/dashboard/:tabType",
  TAG_DETAILS: "/:workspaceId?/tags/:id",
  API_DOCS: "/:workspaceId?/api-docs",
  OBJECTIVES: "/:workspaceId?/objectives",
  APP_HOME: "/:workspaceId?",
};

// Exclude paths that should not be redirected to fallback
const excludedPaths = [URL.DATASOURCE];

// any urls that dont require authentication
export const PUBLIC_URLS = [URL.LOGOUT];

export const TEAM_TAB = {
  DETAILS: "details",
  MEMBERSHIPS: "memberships",
  STRATEGY: "strategy",
  ASSOCIATIONS: "associations",
  VISUALISATION: "visualisation",
  PRINCIPLES: "principles",
  PLANNER: "planner", // Deprecated. TODO: Temporarily added as per customer request. Should remove after 3-4 months.
  REQUEST_ALLOCATION_NUMBERS: "request-allocations",
  APPROVE_ALLOCATION_NUMBERS: "approve-allocations",
  INDIVIDUAL_ALLOCATIONS: "individual-allocations",
  INDIVIDUAL_ALLOCATIONS_PERSON: "individual-allocations-person",
  REQUEST_ALLOCATION_PEOPLE: "assign-to-teams",
  APPROVE_ALLOCATION_PEOPLE: "assign-to-groups",
  FORECAST: "forecast",
  HISTORY: "history",
  METRICS: "metrics",
};

export const PERSON_TAB = {
  DETAILS: "details",
  VISUALISATION: "visualisation",
  ALLOCATIONS: "allocations",
  USER_DASHBOARD: "dashboard",
  HISTORY: "history",
};

export const pathList = values(URL);

export const matchPaths = (pathUrl, pathListToMatch = pathList) => {
  const routeMatch = matchPath(pathUrl || window.location.pathname, {
    path: pathListToMatch,
    exact: true,
    strict: true,
  });

  return routeMatch;
};

export const workspaceIdFromUrl = () => matchPaths()?.params?.workspaceId;

export const url = (path, params) => {
  const workspaceId = workspaceIdFromUrl();
  return generatePath(path, { ...params, workspaceId });
};

const isPathExcludedFromFallback = (value) => {
  return includes(excludedPaths, value);
};

export const fallbackPath = (pathUrl) => {
  const currentPath = pathUrl || window.location.pathname;
  const pathListWithIdParam = filter(
    values(URL),
    (value) => includes(value, ":id") && !isPathExcludedFromFallback(value)
  );
  const matchedPath = matchPaths(currentPath, pathListWithIdParam);
  if (matchedPath) {
    const fallback = replace(matchedPath.path, /\/:id.*/, "");
    return url(fallback);
  }
  return currentPath;
};

export default URL;
