import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import { Spacer } from "orcs-design-system";

import PATHS, { url } from "src/consts/urlPaths";
import { useCheckReportingResourceProvisioned } from "src/contexts/global/WorkspaceContext/WorkspaceContext";
import { ALLOWED_ROLES } from "src/services/auth";
import { canPerform } from "../Can";
import AppSwitchLink from "./AppSwitchLink";
import shouldShowReportsLink from "./utils/reports.utils";
import OpenPageLink from "./OpenPageLink";

const InternalAppLinks = ({ user, roles, workspace }) => {
  const isReportingInfraProvisioned = useCheckReportingResourceProvisioned();
  const reportsURL = get(workspace, "config.tenantConfig.reportsUrl");

  const showReportsLink = shouldShowReportsLink({
    workspace,
    isReportingInfraProvisioned,
    user,
    roles,
  });

  const showAuth0UserReportLink = canPerform(
    user,
    roles,
    ALLOWED_ROLES.USER_REPORT
  );

  return (
    <Spacer mb="r">
      {showReportsLink && (
        <AppSwitchLink
          label="Reports"
          description="Reporting dashboard shows you all insights and numbers from your data"
          href={reportsURL}
        />
      )}
      {showAuth0UserReportLink && (
        <OpenPageLink
          label="User Report"
          description="A report of all users currently registered to login to the TeamForm App"
          path={url(PATHS.USER_REPORT)}
        />
      )}
    </Spacer>
  );
};

InternalAppLinks.propTypes = {
  user: PropTypes.object,
  workspace: PropTypes.object,
  roles: PropTypes.array,
};

export default InternalAppLinks;
