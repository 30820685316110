import React from "react";
import { PropTypes } from "prop-types";
import { matchPaths } from "src/consts/urlPaths";
import useStrategyInsights from "./strategyInsights/hooks/useStrategyInsights";
import { Provider } from ".";

const ValueGraphInsightsProvider = ({ children, match = null }) => {
  const urlMatch = match || matchPaths();
  const { strategyInsights, insightsDispatch } = useStrategyInsights(urlMatch);

  return (
    <Provider
      strategyInsights={strategyInsights}
      insightsDispatch={insightsDispatch}
    >
      {children}
    </Provider>
  );
};

ValueGraphInsightsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  match: PropTypes.object,
};

export default ValueGraphInsightsProvider;
