import React from "react";
import { get, map, sortBy } from "lodash";
import pluralize from "pluralize";
import * as config from "src/config/search";
import { filterTypes } from "src/pages/SearchPage/SearchPage.config";
import { numberToLocaleString } from "src/util/toLocaleString";
import PersonContactDetails from "src/components/PersonContactDetails";
import {
  getSearchPath,
  getTeamsPath,
  getTagsPath,
  getPeoplePath,
} from "src/util/paths";

import { getName, getInitials } from "src/util/person";

import { getActiveTheme } from "src/services/localStorage";

export const sanitiseString = (string) =>
  string ? string.replace(/[^a-zA-Z0-9 -]/g, "") : string;

export const getMemberCountDisplay = (memberCount) =>
  `${numberToLocaleString(memberCount)} ${pluralize("member", memberCount)}`;

export const getPeopleResults = (peopleData, isLoading = false) => {
  return map(peopleData, (person, idx) => {
    const link = getPeoplePath(person.aggregateId);
    const title = getName(person);
    const initials = getInitials(person);
    const { avatar: image, jobTitle: subtitle, aggregateId } = person;

    return {
      label: getName(person),
      type: config.searchEntityType.PERSON,
      value: {
        CustomActionsComponent: () => (
          <PersonContactDetails person={person} tooltipDirection="left" />
        ),
        attributes: get(person, "attributes"),
        badge: {
          title,
          subtitle,
          initials,
          image,
        },
        link,
        template: "optionWithAction",
        type: "people",
        aggregateId,
        person,
        isLoading,
        customStyles: idx === peopleData.length - 1 && peopleData.length < 3,
      },
    };
  });
};

export const getTeamResults = (groupData) => {
  return map(groupData, (team, idx) => {
    const link = getTeamsPath(team.id);
    const memberCount = getMemberCountDisplay(team.memberCount);

    return {
      label: team.name,
      type: config.searchEntityType.TEAM,
      value: {
        group: team,
        memberCount,
        link,
        template: "option",
        type: "teams",
        customStyles: idx === groupData.length - 1 && groupData.length < 3,
      },
    };
  });
};

export const getTagResults = (tags) => {
  return map(tags, (tag, idx) => {
    return {
      label: tag.displayValue,
      type: config.searchEntityType.TAG,
      value: {
        tag,
        link: getTagsPath(tag.id),
        template: "optionTag",
        type: "tags",
        customStyles: idx === tags.length - 1 && tags.length < 3,
      },
    };
  });
};

export const getOptionLink = (searchTerm, count, found, label, type) => {
  return {
    label: "",
    options: [
      {
        label,
        value: {
          url: getSearchPath(type, searchTerm),
          count,
          found,
          template: "link",
          type,
        },
      },
    ],
  };
};

export const getPersonSearchAfterId = (data) => {
  return get(data, "result.pagedPeople.searchAfterId");
};

export const hasMoreResults = (data, type) => {
  const peopleFound = get(data, "result.pagedPeople.people.length");
  const peopleCount = get(data, "result.pagedPeople.totalCount");
  const teamFound = get(data, "result.pagedGroups.groups.length");
  const teamCount = get(data, "result.pagedGroups.totalCount");
  const hasMorePeople = peopleFound < peopleCount;
  const hasMoreTeams = teamFound < teamCount;

  if (type === filterTypes.PEOPLE) {
    return hasMorePeople;
  }
  if (type === filterTypes.TEAMS) {
    return hasMoreTeams;
  }
  if (type === filterTypes.ALL) {
    return hasMorePeople || hasMoreTeams;
  }
  return false;
};

export const getFormattedSearchResults = ({
  data,
  searchTerm,
  tagsSearchResult,
  isHideLabels = false,
  isHideExtraLinks = false,
  isForSearchPage = false,
  isLoading = false,
  customer,
}) => {
  const people = getPeopleResults(
    get(data, "result.pagedPeople.people"),
    isLoading
  );
  const teams = getTeamResults(
    get(data, "result.pagedGroups.groups"),
    customer
  );

  const tags = getTagResults(
    get(tagsSearchResult, "tags") || get(data, "result.pagedTags.tags")
  );

  const peopleFound = get(data, "result.pagedPeople.people.length");
  const peopleCount = get(data, "result.pagedPeople.totalCount");
  const teamFound = get(data, "result.pagedGroups.groups.length");
  const teamCount = get(data, "result.pagedGroups.totalCount");
  const tagsFound =
    get(tagsSearchResult, "tags.length") ||
    get(data, "result.pagedTags.tags.length");
  const tagsCount =
    get(tagsSearchResult, "totalCount") ||
    get(data, "result.pagedTags.totalCount");

  const hasMorePeople = peopleFound < peopleCount;
  const hasMoreTeams = teamFound < teamCount;
  const hasMoreTags = tagsFound < tagsCount;

  const peopleLink = getOptionLink(
    searchTerm,
    peopleCount,
    peopleFound,
    "People Link",
    "people"
  );

  const teamLink = getOptionLink(
    searchTerm,
    teamCount,
    teamFound,
    "Team Link",
    "teams"
  );

  const tagSearchPageLink = getOptionLink(
    searchTerm,
    tagsCount,
    tagsFound,
    "Tag Link",
    "tags"
  );

  const response = [];

  response.push({
    label: isHideLabels ? "" : "People",
    options: people,
    counters: {
      total: peopleCount,
      found: peopleFound,
    },
  });

  if (hasMorePeople && !isForSearchPage && !isHideExtraLinks) {
    response.push(peopleLink);
  }

  response.push({
    label: isHideLabels ? "" : "Teams",
    options: teams,
    counters: {
      total: teamCount,
      found: teamFound,
    },
  });

  if (hasMoreTeams && !isForSearchPage && !isHideExtraLinks) {
    response.push(teamLink);
  }

  response.push({
    label: isHideLabels ? "" : "Tags",
    options: tags,
    counters: {
      total: tagsCount,
      found: tagsFound,
    },
  });

  if (hasMoreTags && !isForSearchPage && !isHideExtraLinks) {
    response.push(tagSearchPageLink);
  }

  if (isForSearchPage && !isHideExtraLinks) {
    response.push({
      hasMorePeople,
      hasMoreTeams,
    });
  }

  return response;
};

export const getSearchResultsMergedWithPaginatedData = (prevData, newData) => {
  const prevPeople = get(prevData, "result.pagedPeople.people");
  const newPeople = get(newData, "result.pagedPeople.people", []);
  const prevGroups = get(prevData, "result.pagedGroups.groups");
  const newGroups = get(newData, "result.pagedGroups.groups", []);
  const prevTags = get(prevData, "result.pagedTags.tags");
  const newTags = get(newData, "result.pagedTags.tags", []);

  const result = { ...newData.result };

  if (prevPeople) {
    result.pagedPeople = {
      ...newData.result.pagedPeople,
      people: [...prevPeople, ...newPeople],
    };
  }

  if (prevGroups) {
    result.pagedGroups = {
      ...newData.result.pagedGroups,
      groups: [...prevGroups, ...newGroups],
    };
  }

  if (prevTags) {
    result.pagedTags = {
      ...newData.result.pagedTags,
      tags: [...prevTags, ...newTags],
    };
  }

  return {
    ...prevData,
    result,
  };
};

export const createPlaceholderData = (length) => {
  const getOptions = (type) =>
    Array(length).fill({
      value: {},
      type,
    }); // creates [{value: {}, type: "person"}, {value: {}, type: "person"} ...]

  return [
    {
      options: getOptions(config.searchEntityType.PERSON),
    },
    {
      options: getOptions(config.searchEntityType.TEAM),
    },
  ];
};

export const highlightSelectedString = (text, highlights) => {
  if (!text) {
    return text;
  }

  if (highlights === undefined) {
    return text;
  }

  if (highlights.length < 2) {
    return text;
  }

  const highlightsEscaped = highlights.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(highlightsEscaped, "gi");

  const style = "background: rgba(0,122,196,0.1);";
  const divLineHeight = getActiveTheme() === "compact" ? "1.2rem" : "1.4rem";

  return React.createElement("div", {
    style: { lineHeight: divLineHeight },
    "data-testid": text,
    dangerouslySetInnerHTML: {
      __html: text.replace(regex, `<span style="${style}">$&</span>`),
    },
  });
};

export const teamsToRender = (person, groupTypes) => {
  const supplyTeams = [];
  const demandTeams = [];

  map(person?.memberOf, (item) => {
    const groupType = groupTypes[item.type];

    if (groupType?.isSupply) {
      supplyTeams.push(item);
    }

    if (groupType?.isDemand) {
      demandTeams.push(item);
    }
  });

  return [...sortBy(supplyTeams, ["name"]), ...sortBy(demandTeams, ["name"])];
};
