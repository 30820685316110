import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { POWER_ROLE, ADMIN_ROLE } from "src/services/auth";
import useDefaultRoutePath from "./useDefaultRoutePath";
import useUserRole from "./useUserRole";

const RestrictedRoute = ({ children, allowedRoles, ...props }) => {
  const userRole = useUserRole();
  const defaultRoutePath = useDefaultRoutePath();

  const isAuthorized = allowedRoles.includes(userRole);

  if (isAuthorized) {
    return <Route {...props}>{children}</Route>;
  }

  return <Redirect to={{ pathname: defaultRoutePath }} />;
};

RestrictedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
};

RestrictedRoute.defaultProps = {
  allowedRoles: [POWER_ROLE, ADMIN_ROLE],
};

export default RestrictedRoute;
