import { get, includes, isEmpty } from "lodash";
import flagsmith from "flagsmith";

/**
 * TODO: We should have good typing for something as important as auth.
 *  This is just a start to it and may be incomplete.
 *
 * @typedef {Object} Auth0UserMetaDataTenant
 * @prop {string} name
 * @prop {string} tenantId
 * @prop {string} tenantUrl
 *
 * @typedef {Object} Auth0UserMetaData
 * @prop {string} operatorId
 * @prop {string} redashName1
 * @prop {string} redashName2
 * @prop {string[]} groups
 * @prop {string[]} permissions
 * @prop {string[]} roles
 * @prop {string[]} workspaceRoles
 * @prop {{tenants: Auth0UserMetaDataTenant[]}} tenantList
 *
 * @typedef {Object} Auth0User
 * @prop {string} email
 * @prop {boolean} email_verified
 * @prop {string} name
 * @prop {string} nickname
 * @prop {string} sub
 * @prop {Date} updated_at
 * @prop {string} [auth0Namespace]/created_at
 * @prop {string} [auth0Namespace]/email
 * @prop {string} [auth0Namespace]/preferred_name
 * @prop {Auth0UserMetaData} [auth0Namespace]/user_metadata
 *
 * @typedef {Object} AuthStub
 * @prop {string} preferredUsername
 * @prop {string} username
 * @prop {string} email
 * @prop {Date} createdAt
 * @prop {number} loginsCount
 * @prop {string} operatorId
 * @prop {string} tenantId
 * @prop {string[]} groups
 * @prop {string[]} roles
 */

const INTERNAL_SUPPORT_EMAIL_DOMAINS = [
  "support.orchestrated.io",
  "support.teamform.co",
];

const INTERNAL_TEST_EMAIL_DOMAINS = [
  "test.orchestrated.io",
  "social.teamform.co",
];

export const UNKNOWN_USER = "UNKNOWN_USER";
export const POWER_ROLE = "POWER";
export const ADMIN_ROLE = "ADMIN";
export const DIRECTORY_ROLE = "DIRECTORY";
export const REPORTING_ROLE = "REPORTING";
export const ALLOWED_ROLES = {
  REPORTING: [POWER_ROLE, ADMIN_ROLE],
  USER_REPORT: [ADMIN_ROLE],
};

export const auth0Namespace = "http://orchestrated.io/2020/03/identity/claims";

// TODO: Move relevant metadata from user to app metadata object
// export const auth0AppMetadataKey = `${auth0Namespace}/app_metadata`;
export const auth0UserMetadataKey = `${auth0Namespace}/user_metadata`;

// this is compiled, no risk of timing attack
export const isProdAuth0Tenant =
  process.env.REACT_APP_AUTH0_DOMAIN === "id.orchestrated.io";

export const completeLogout = () => {
  flagsmith.logout();
  window.localStorage.clear();
  window.location.replace(process.env.REACT_APP_DOMAIN);
};

export const getAuth0ConnectionFromHostname = (connection) => {
  return connection
    ? {
        connection,
      }
    : null;
};

export const getApiUrlFromAuth0 = (apiUrl, tenantId, overrideTenant) => {
  if (overrideTenant) {
    return `${overrideTenant.tenantUrl}/${overrideTenant.tenantId}`;
  }

  const baseUrl = apiUrl || process.env.REACT_APP_GRAPHQL_URL;
  return process.env.REACT_APP_IS_OFFLINE === "true" || !tenantId
    ? `${process.env.REACT_APP_GRAPHQL_URL}`
    : `${baseUrl}/${tenantId}`;
};

const getFromCustomClaims = (user, key) => {
  return get(user, `${auth0Namespace}/${key}`);
};

const getAuth0UserMetadata = (user) => {
  return getFromCustomClaims(user, "user_metadata");
};

const getFromUserMetadata = (user, key, defaultValue) => {
  return get(getAuth0UserMetadata(user), key, defaultValue);
};

/**
 * @param {Auth0User|AuthStub} userOrStub
 * @returns {any}
 */
export const getFromUserOrAuth = (userOrStub, key, defaultValue) => {
  // get from user object (see createAuth for context)
  const stubField = get(userOrStub, key);
  if (stubField !== undefined) {
    return stubField;
  }

  // get from user metadata
  const userField = get(getAuth0UserMetadata(userOrStub), key);
  if (userField !== undefined) {
    return userField;
  }

  return defaultValue;
};

/**
 * @param {Auth0User|AuthStub} userOrStub
 * @returns {boolean}
 */
export const isInternalSupportUser = (userOrStub) => {
  const email = getFromUserOrAuth(userOrStub, "email", "");
  return INTERNAL_SUPPORT_EMAIL_DOMAINS.includes(email.split("@")[1]);
};

/**
 * @param {Auth0User|AuthStub} userOrStub
 * @returns {boolean}
 */
export const isInternalTestUser = (userOrStub) => {
  const email = getFromUserOrAuth(userOrStub, "email", "");
  return INTERNAL_TEST_EMAIL_DOMAINS.includes(email.split("@")[1]);
};

/**
 * @param {Auth0User|AuthStub} userOrStub
 * @returns {boolean}
 */
export const isOnboardingUser = (userOrStub) => {
  return getFromUserOrAuth(userOrStub, "tenantStatus") === "ONBOARDING_START";
};

/**
 * @param {Auth0User|AuthStub} userOrStub
 * @returns {boolean}
 */
export const hasTenantPrompt = (userOrStub) => {
  return (
    Boolean(getFromUserOrAuth(userOrStub, "tenantList")) &&
    (isInternalSupportUser(userOrStub) || isInternalTestUser(userOrStub))
  );
};

/**
 * @param {Auth0User|AuthStub} userOrStub
 * @returns {boolean}
 */
export const hasAdminRole = (userOrStub) => {
  return getFromUserOrAuth(userOrStub, "roles", []).includes(ADMIN_ROLE);
};

/**
 * @param {Auth0User|AuthStub} userOrStub
 * @returns {boolean}
 */
export const hasPowerRole = (userOrStub) => {
  return getFromUserOrAuth(userOrStub, "roles", []).includes(POWER_ROLE);
};

/**
 * @param {Auth0User|AuthStub} userOrStub
 * @returns {boolean}
 */
export const hasDirectoryRole = (userOrStub) => {
  return getFromUserOrAuth(userOrStub, "roles", []).includes(DIRECTORY_ROLE);
};

/**
 * @param {Auth0User|AuthStub} userOrStub
 * @returns {boolean}
 */
export const hasAdminOrPowerRole = (userOrStub) => {
  const roles = getFromUserOrAuth(userOrStub, "roles", []);
  return includes(roles, ADMIN_ROLE) || includes(roles, POWER_ROLE);
};

/**
 * @param {Auth0User|AuthStub} userOrStub
 * @returns {Auth0User|AuthStub}
 */
export const getImpersonatedUser = (user) => {
  if (user && isInternalSupportUser(user)) {
    const impersonation = getFromUserMetadata(user, "impersonation");
    let roles = getFromUserMetadata(user, "roles");
    if (!isEmpty(impersonation?.roles)) {
      roles = impersonation.roles;
    }

    let email = getFromUserOrAuth(user, "email", "");
    if (!isEmpty(impersonation?.email)) {
      email = impersonation.email;
    }

    const impersonatedUser = {
      ...user,
      email,
      [`${auth0Namespace}/email`]: email,
      [`${auth0Namespace}/user_metadata`]: {
        ...getAuth0UserMetadata(user),
        roles,
      },
    };
    return impersonatedUser;
  }
  return user;
};

/**
 * @param {Auth0User} user
 * @returns {AuthStub}
 */
export const createAuth = (user) => ({
  preferredUsername: getFromCustomClaims(user, "preferred_name"),
  username: get(user, "name"),
  email: get(user, "email"),
  createdAt: getFromCustomClaims(user, "created_at"),
  loginsCount: getFromCustomClaims(user, "logins_count"),
  operatorId: getFromUserMetadata(user, "operatorId") || get(user, "sub"),
  tenantId: getFromUserMetadata(user, "tenantId"),
  groups: getFromUserMetadata(user, "groups"),
  roles: getFromUserMetadata(user, "roles", []),
});
