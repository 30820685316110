import constate from "constate";

const useFeatureFlagsContext = (values) => values;

const [Provider, useFeatureFlags] = constate(
  useFeatureFlagsContext,
  (value) => value?.featureFlags
);

const useFeatureFlag = (flag, defaultValue = null) => {
  const featureFlags = useFeatureFlags();
  if (featureFlags?.[flag]) {
    return featureFlags[flag];
  }
  return defaultValue;
};

export { Provider, useFeatureFlags, useFeatureFlag };
