import React from "react";
import { Card, Divider, Flex, H5, P, StatusDot } from "orcs-design-system";
import { map } from "lodash";
import PropTypes from "prop-types";

const InsightPanel = ({ insight }) => {
  return (
    <Card>
      <Flex flexDirection="column">
        <Flex
          mb="s"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Flex flexDirection="row" alignItems="center">
            <StatusDot variant="warning" mr="s" />
            <H5 fontWeight={600}>{insight.displayName}</H5>
          </Flex>
        </Flex>
        <Divider light />
        <Flex mt="s" mb="s">
          <small>{insight?.description}</small>
        </Flex>
        <Flex flexDirection="column">
          {map(insight.data, (data, index) => {
            return (
              <React.Fragment key={index}>
                <Flex mb="xs" mt="s" justifyContent="space-between">
                  <P fontSize="1.2rem">{data?.group.name}</P>
                  <P fontSize="1.2rem">
                    {data.value}
                    {insight?.measureType === "percentage" ? "%" : ""}
                  </P>
                </Flex>
                <Divider dash light />
              </React.Fragment>
            );
          })}
        </Flex>
      </Flex>
    </Card>
  );
};

InsightPanel.propTypes = {
  insight: PropTypes.objectOf({
    displayName: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    data: PropTypes.arrayOf({
      group: PropTypes.objectOf({
        name: PropTypes.string,
      }),
      value: PropTypes.number,
    }),
    measureType: PropTypes.string,
  }),
};

export default React.memo(InsightPanel);
