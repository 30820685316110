import { ACTION } from "./action";

const strategyInsightsReducer = (state, action) => {
  switch (action.type) {
    case ACTION.SET_INSIGHTS_ORDER:
      return {
        ...state,
        insightsOrder: action.payload,
      };
    default:
      return state;
  }
};

const strategyInsightInitialState = {
  insightsOrder: [],
  highlightInVisualizer: [],
};

export { strategyInsightsReducer, strategyInsightInitialState };
