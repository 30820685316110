/* istanbul ignore file */

import { Box, Flex, Small, Toggle } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { isInternalSupportUser, isProdAuth0Tenant } from "src/services/auth";
import SelectTenantPrompt from "src/components/SelectTenantPrompt";
import { saveActiveTenant } from "src/services/localStorage";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { useMaintenanceMode } from "src/contexts/global/GlobalContext";

import ProdConfirmationDialog from "./ProdConfirmationDialog";

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  z-index: 22;
`;

const geInitialMode = (overrideTenant) => {
  if (overrideTenant?.canRunMutation !== undefined) {
    return !overrideTenant?.canRunMutation;
  }

  return isProdAuth0Tenant;
};

const EnvInformationBar = (props) => {
  const theme = useTheme();
  const workspace = useWorkspace();
  const maintenanceMode = useMaintenanceMode();

  const { user, overrideTenant, isDemo } = props;

  const isMaintenanceMode =
    maintenanceMode === "true" && isInternalSupportUser(user.email);
  const [isReadonlyMode, setIsReadonlyMode] = useState(
    geInitialMode(overrideTenant)
  );
  const [isModalVisible, setModalVisible] = useState(false);

  const customer = workspace?.config?.customer;
  const workspaceId = workspace?.id;

  useEffect(() => {
    if (
      isDemo ||
      (!isProdAuth0Tenant &&
        overrideTenant &&
        overrideTenant.canRunMutation === undefined)
    ) {
      overrideTenant.canRunMutation = true;
    }
    // Only need to do this for test env, so no need to listen to overrideTenant change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isDemo) {
    return null;
  }

  const onConfirm = () => {
    const nextMode = !isReadonlyMode;
    setIsReadonlyMode(nextMode);

    // Add attribute to overrideTenant to enable mutating
    if (overrideTenant) {
      overrideTenant.canRunMutation = !nextMode;
      saveActiveTenant(user.email, overrideTenant);
    }

    setModalVisible(false);
  };

  const onReadonlyModeToggle = () => {
    if (isProdAuth0Tenant && isReadonlyMode) {
      setModalVisible(true);
    } else {
      onConfirm();
    }
  };

  const onClose = () => {
    setModalVisible(false);
  };

  let envBarColour;
  if (isMaintenanceMode) {
    envBarColour = theme?.colors?.danger;
  } else if (isReadonlyMode) {
    envBarColour = theme?.colors?.warningDark;
  } else if (isProdAuth0Tenant) {
    envBarColour = theme?.colors?.danger;
  } else {
    envBarColour = theme?.colors?.success;
  }

  return (
    <Wrapper bg={envBarColour} color="white" px="r" py="xxs" id="appEnvBar">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        flexWrap="wrap"
      >
        <Flex flexWrap="wrap">
          <Small weight="bold">Environment information</Small>
          <Small ml="s">{`code: ${customer}`}</Small>
          <Small ml="s">{` | tenant: ${overrideTenant?.tenantId}`}</Small>
          <Small ml="s">{` | workspace: ${workspaceId}`}</Small>
          <Small ml="s">{` | ${overrideTenant?.label} Environment`}</Small>
          {isMaintenanceMode && (
            <Small ml="s" weight="bold">{` | ⚙️ MAINTENANCE MODE ⚙️`}</Small>
          )}
        </Flex>
        <Flex alignItems="center" flexWrap="wrap">
          <SelectTenantPrompt {...props} isSelectOnly />
          <Box ml="s" bg="greyDarker" p="xs" pr="s" borderRadius="14px">
            <Toggle
              id="env-info-change-readonly"
              label="Allow changes"
              small
              inverted
              onChange={onReadonlyModeToggle}
              checked={!isReadonlyMode}
            />
          </Box>
        </Flex>
      </Flex>
      {isProdAuth0Tenant && (
        <ProdConfirmationDialog
          visible={isModalVisible}
          onConfirm={onConfirm}
          onClose={onClose}
        />
      )}
    </Wrapper>
  );
};

EnvInformationBar.propTypes = {
  user: PropTypes.object.isRequired,
  overrideTenant: PropTypes.object.isRequired,
  isDemo: PropTypes.bool,
};

export default EnvInformationBar;
