import { get } from "lodash";

import { hasAdminRole } from "src/services/auth";

const useShowInvite = ({ workspace, user }) => {
  const tenantConfig = get(workspace, "config.tenantConfig", {});
  return hasAdminRole(user) && get(tenantConfig, "canInviteUser", false);
};

export default useShowInvite;
