import React from "react";

const Logo = () => {
  return (
    <svg
      width="111"
      height="14"
      viewBox="0 0 111 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      role="img"
      aria-labelledby="svgLogoAlt"
    >
      <title id="svgLogoAltTitle">TeamForm</title>
      <path
        d="M4.74419 13.881H6.70116V1.72521H11.4454V0H0V1.72521H4.74419V13.881Z"
        fill="white"
      />
      <path
        d="M18.046 12.3343C16.2867 12.3343 14.982 11.5014 14.4483 10.0538L22.7704 8.42776C22.7111 5.29462 20.4774 3.27195 17.6111 3.27195C14.5669 3.27195 12.353 5.51275 12.353 8.62606C12.353 11.7592 14.6064 14 17.9867 14C19.7262 14 21.1692 13.4051 22.0983 12.2946L21.0704 11.0652C20.2995 11.9178 19.2716 12.3343 18.046 12.3343ZM17.6111 4.87819C19.3111 4.87819 20.5564 5.94901 20.8925 7.45609L14.1913 8.72521V8.52691C14.1913 6.32578 15.6146 4.87819 17.6111 4.87819Z"
        fill="white"
      />
      <path
        d="M33.4113 3.3711V4.95751C32.5415 3.8272 31.2369 3.27195 29.7543 3.27195C26.6904 3.27195 24.4567 5.4136 24.4567 8.62606C24.4567 11.8385 26.6904 14 29.7543 14C31.2962 14 32.6404 13.4051 33.4904 12.2153V13.881H35.309V3.3711H33.4113ZM29.9125 12.3343C27.8962 12.3343 26.3741 10.8669 26.3741 8.62606C26.3741 6.38527 27.8962 4.93768 29.9125 4.93768C31.909 4.93768 33.4311 6.38527 33.4311 8.62606C33.4311 10.8669 31.909 12.3343 29.9125 12.3343Z"
        fill="white"
      />
      <path
        d="M52.0054 3.27195C50.2263 3.27195 48.7635 4.0255 47.9728 5.2153C47.281 3.90652 45.9368 3.27195 44.3356 3.27195C42.7542 3.27195 41.4693 3.86686 40.7182 4.93768V3.3711H38.8996V13.881H40.7972V8.46742C40.7972 6.18697 42.0426 4.97734 43.9403 4.97734C45.66 4.97734 46.6484 5.98867 46.6484 8.05099V13.881H48.5461V8.46742C48.5461 6.18697 49.7914 4.97734 51.6891 4.97734C53.4089 4.97734 54.3972 5.98867 54.3972 8.05099V13.881H56.2949V7.83286C56.2949 4.73938 54.5554 3.27195 52.0054 3.27195Z"
        fill="white"
      />
      <path
        d="M69.8285 1.72521V0H60.0634V13.881H62.0401V8.26912H68.9785V6.56374H62.0401V1.72521H69.8285Z"
        fill="white"
      />
      <path
        d="M76.8215 14C79.9646 14 82.2576 11.7592 82.2576 8.62606C82.2576 5.49292 79.9646 3.27195 76.8215 3.27195C73.6785 3.27195 71.3657 5.49292 71.3657 8.62606C71.3657 11.7592 73.6785 14 76.8215 14ZM76.8215 12.3343C74.8053 12.3343 73.2832 10.8669 73.2832 8.62606C73.2832 6.38527 74.8053 4.93768 76.8215 4.93768C78.8378 4.93768 80.3402 6.38527 80.3402 8.62606C80.3402 10.8669 78.8378 12.3343 76.8215 12.3343Z"
        fill="white"
      />
      <path
        d="M86.7006 5.13598V3.3711H84.882V13.881H86.7797V8.64589C86.7797 6.34561 88.0448 5.09632 90.0809 5.09632C90.2192 5.09632 90.3576 5.09632 90.5158 5.11615V3.27195C88.6774 3.27195 87.3727 3.90652 86.7006 5.13598Z"
        fill="white"
      />
      <path
        d="M105.96 3.27195C104.181 3.27195 102.719 4.0255 101.928 5.2153C101.236 3.90652 99.8919 3.27195 98.2907 3.27195C96.7093 3.27195 95.4244 3.86686 94.6733 4.93768V3.3711H92.8546V13.881H94.7523V8.46742C94.7523 6.18697 95.9977 4.97734 97.8953 4.97734C99.6151 4.97734 100.603 5.98867 100.603 8.05099V13.881H102.501V8.46742C102.501 6.18697 103.747 4.97734 105.644 4.97734C107.364 4.97734 108.352 5.98867 108.352 8.05099V13.881H110.25V7.83286C110.25 4.73938 108.51 3.27195 105.96 3.27195Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
