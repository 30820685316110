import PropTypes from "prop-types";
import { POWER_ROLE, ADMIN_ROLE } from "src/services/auth";
import useUserRole from "./useUserRole";

const Restricted = ({ children, allowedRoles, fallback }) => {
  const userRole = useUserRole();

  const isAuthorized = allowedRoles.includes(userRole);
  return isAuthorized ? children : fallback;
};

Restricted.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fallback: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
};

Restricted.defaultProps = {
  allowedRoles: [POWER_ROLE, ADMIN_ROLE],
  fallback: null,
};

export default Restricted;
