import { useMutation } from "@apollo/client";
import { map, isEmpty, trim, includes, pullAt, toLower } from "lodash";
import { Box, Notification } from "orcs-design-system";
import React, { useState, useCallback, useEffect } from "react";

import { trackEvent } from "src/services/segment";
import ErrorNotification from "src/components/ErrorNotification";
import { POWER_ROLE } from "src/services/auth";

import { EVENT_TRACKING } from "src/consts/eventTracking";
import { inviteUsers } from "./queries/person.graphql";

import EmailList from "./EmailList";
import Footer from "./Footer";
import Header from "./Header";
import InviteNotification from "./InviteNotification";

const InviteUser = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [emails, setEmails] = useState([]);
  const [curEmail, setCurEmail] = useState("");

  const [inviteUsersMutation, { data, error: inviteUserMutationError }] =
    useMutation(inviteUsers);

  useEffect(() => {
    setEmails([]);
    setCurEmail("");
    setIsProcessing(false);
  }, []);

  const onEmailChange = useCallback((e) => {
    setCurEmail(e.target.value);
  }, []);

  const onAddEmail = () => {
    const normalised = toLower(trim(curEmail));
    if (!includes(emails, normalised)) {
      setEmails([...emails, normalised]);
    }
    setCurEmail("");
  };

  const onRemoveEmail = (index) => () => {
    pullAt(emails, [index]);
    setEmails([...emails]);
  };

  const onInviteUser = useCallback(async () => {
    // In day1 feature, only POWER_ROLE is supported.
    const emailWithRoles = map(emails, (email) => {
      return {
        email,
        role: POWER_ROLE,
      };
    });

    const InviteUserInput = {
      invites: emailWithRoles,
    };

    setIsProcessing(true);
    try {
      await inviteUsersMutation({
        variables: { input: InviteUserInput },
      });
    } finally {
      setIsProcessing(false);
      setInviteSent(true);
      trackEvent(EVENT_TRACKING.USER_INVITED, {
        invitee_email: emails.join(", "),
      });
    }
  }, [emails, inviteUsersMutation]);

  const isDisabled = isEmpty(emails) ? "disabled" : "success";

  return (
    <>
      <Header
        onEmailChange={onEmailChange}
        curEmail={curEmail}
        onAddEmail={onAddEmail}
        mailCount={emails.length}
      />
      <Box px="xs" pb="r">
        <EmailList
          emails={emails}
          onRemoveEmail={onRemoveEmail}
          isProcessing={isProcessing}
        />

        {isProcessing && !inviteUserMutationError && (
          <Notification mt="r" loading closable={false}>
            Inviting users in progress...
          </Notification>
        )}
        {inviteUserMutationError && (
          <ErrorNotification
            message="Sorry, an error occurred, please refresh and send invitation again."
            error={inviteUserMutationError}
          />
        )}
      </Box>
      <Footer
        isDisabled={isDisabled}
        onInviteUser={onInviteUser}
        isProcessing={isProcessing}
        mailCount={emails.length}
      />
      {inviteSent && <InviteNotification data={data} />}
    </>
  );
};

export default React.memo(InviteUser);
