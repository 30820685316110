import { matchPath } from "react-router";
import { get } from "lodash";
import { uiShaShort, uiBuild } from "src/util/diagnostics";
import { genericPathname } from "src/util/url";
import {
  createAuth,
  hasAdminRole,
  hasPowerRole,
  hasDirectoryRole,
  isInternalSupportUser,
} from "./auth";
// analytics is a global var injected by segment script
// analytics is not initialised on for local

// if window.analytics is not present, use this as last resort
const nullAnalyticsProxy = () => {
  return {
    identify: () => {},
    track: () => {},
    page: () => {},
    group: () => {},
    alias: () => {},
  };
};
let storedTenantId = null;
// analytics is a global var injected by segment script
// analytics is not initialised on for local
// evaluate from window on use, to avoid accessing stale pre-init version and losing messages
const getAnalytics = () => {
  return window.analytics || nullAnalyticsProxy();
};

const sha = uiShaShort() ?? "";
const build = uiBuild() ?? "";

export const trackPage = (pathname, extraTracking = {}) => {
  const analytics = getAnalytics();

  if (!analytics) {
    return;
  }

  const name = genericPathname(pathname);
  const properties = { uiVersion: sha, uiBuild: build, ...extraTracking };
  const options = storedTenantId ? { groupId: storedTenantId } : undefined;
  if (name !== "") {
    analytics.page(name, properties, options);
  } else {
    analytics.page(properties, options);
  }
};

export const identify = (user, userPerson) => {
  const analytics = getAnalytics();
  if (analytics && user) {
    const {
      createdAt: signedUp,
      loginsCount,
      preferredUsername,
      email,
      operatorId,
      roles,
      tenantId,
    } = createAuth(user);

    // temp to drop indentity calls for aooc tenant.
    if (tenantId === "aooc") {
      return;
    }

    const internalSupportUser = isInternalSupportUser(user);
    const role = hasAdminRole(user)
      ? "ADMIN"
      : hasPowerRole(user)
      ? "POWER"
      : hasDirectoryRole(user)
      ? "DIRECTORY"
      : null;
    // Date comes in ISO8601 string
    // (i.e. 2020-02-08 09:30:26.123 — with hour, minute, second, and millisecond time part).
    // Intercom integration requires UNIX timestamp (seconds).
    const createdAt = Math.floor(new Date(signedUp).valueOf() / 1000);
    const numberOfAllocations = userPerson
      ? get(userPerson, "allocations", []).length
      : null;
    analytics.identify(operatorId, {
      name: preferredUsername,
      email,
      createdAt,
      created_at: signedUp,
      logins_count: loginsCount,
      roles,
      role,
      is_internal_support_user: internalSupportUser,
      is_user_person: userPerson != null,
      number_of_allocations: numberOfAllocations,
      is_full_time: userPerson?.fte != null ? userPerson.fte >= 0.95 : null,
      span: userPerson?.span != null ? userPerson.span : null,
      layer: userPerson?.layers != null ? userPerson.layers : null,
    });
    if (tenantId && tenantId !== "TBC") {
      analytics.group(tenantId);
      storedTenantId = tenantId;
    }
  }
};

// This method is for extracting page info generated from the params of react-router's current matched path.
export const extractPageInfoForSegment = ({ routes, pathname }) => {
  const matchedPath = routes
    .map((route) => {
      const match = matchPath(pathname, route);

      if (match) return { route, match };

      return false;
    })
    .find((routeWithMatch) => routeWithMatch.match);
  const pageInfo = {};

  if (matchedPath?.match) {
    Object.entries(matchedPath.match.params).forEach(([key, value]) => {
      if (!value) return;

      /**
       * A few route params are generic with id.
       * In this case we rename the key to subjectId and extract the "subject" from the matched path.
       * */
      const isSubjectId = key === "id";

      if (isSubjectId) {
        pageInfo.subject = matchedPath.route.path
          .replace("/:workspaceId?", "")
          .replace(":", "_")
          .split("_")[0]
          .split("/")
          .filter((subStr) => subStr)
          .join("_");

        pageInfo.subjectId = value;
      } else {
        pageInfo[key] = value;
      }
    });
  }

  return pageInfo;
};

export const trackEvent = (event, properties = {}) => {
  const analytics = getAnalytics();
  if (!analytics) {
    return;
  }

  const options = storedTenantId ? { groupId: storedTenantId } : undefined;
  analytics.track(event, properties, options);
};
