/* eslint-disable no-console */
import createAuth0Client from "@auth0/auth0-spa-js";
import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { get, has } from "lodash";

import { completeLogout, getImpersonatedUser } from "src/services/auth";
import { shouldIgnoreError } from "src/util/url";

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  isGenericDomain,
  ...initOptions
}) => {
  const history = useHistory();
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [auth0ErrorContext, setAuth0ErrorContext] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const urlParams = queryString.parse(window.location.search);

      const screenHint = get(urlParams, "screenHint");

      const auth0FromHook = await createAuth0Client({
        ...initOptions,
        ...(screenHint && { screen_hint: screenHint }),
      });

      setAuth0(auth0FromHook);

      if (has(urlParams, "error") && !shouldIgnoreError()) {
        // https://auth0.com/docs/libraries/error-messages
        setIsAccessDenied(true);
        setAuth0ErrorContext({
          source: "auth0",
          error: get(urlParams, "error"),
          errorDescription: get(urlParams, "error_description"),
        });
      } else if (has(urlParams, "code") && has(urlParams, "state")) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          const redirectUrl = appState?.targetUrl || window.location.pathname;
          history.push(redirectUrl);
        } catch (error) {
          // in case there is an error in handleRedirectCallback, redirect the user to home page so the login flow can complete or can be restarted
          console.log(error);
          history.push("/");
        }
      }

      const isAuth = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuth);

      if (isAuth) {
        const auth0User = await auth0FromHook.getUser();
        setUser(getImpersonatedUser(auth0User));
      }
      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }

    setUser(await auth0Client.getUser());
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const theUser = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(getImpersonatedUser(theUser));
  };

  const handleLogout = (...p) => {
    completeLogout();
    // default redirect to originating request URL's logout page
    return auth0Client.logout({
      returnTo: `${window.location.origin}/logout`,
      ...p,
    });
  };

  return (
    <Auth0Context.Provider
      value={{
        auth0ErrorContext,
        isAuthenticated,
        isAccessDenied,
        isGenericDomain,
        user: getImpersonatedUser(user),
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: handleLogout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

Auth0Provider.propTypes = {
  children: PropTypes.node,
  isGenericDomain: PropTypes.bool,
  initOptions: PropTypes.object,
};
