import { useCallback, useRef } from "react";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { useAllocationProject } from "src/contexts/global/GlobalContext";
import getTeamPath from "./getTeamPath";

const useOnNodeSelected = ({ navigateTo }) => {
  const workspace = useWorkspace();
  const activeAllocationProject = useAllocationProject();
  const activeNodeHierarchyRef = useRef(null);

  return useCallback(
    (node) => {
      if (!node) {
        return;
      }

      const { group } = node;

      const pathname = getTeamPath({
        group,
        workspace,
        activeAllocationProject,
      });

      activeNodeHierarchyRef.current = group.hierarchyIds;
      navigateTo(pathname, group.hierarchyIds);
    },
    [activeAllocationProject, navigateTo, workspace]
  );
};

export default useOnNodeSelected;
