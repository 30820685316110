import React from "react";
import { startCase, get } from "lodash";
import PropTypes from "prop-types";
import { HeaderSimple, StyledLink } from "orcs-design-system";

import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import {
  useWorkspaces,
  useWorkspace,
} from "src/contexts/global/WorkspaceContext";
import { useActiveTenant } from "src/contexts/global/ApolloSettingsContext";
import {
  useAuth,
  useUserPerson,
  useGlobalConfig,
} from "src/contexts/global/GlobalContext";
import { useAuth0 } from "src/contexts/auth0Provider";
import { useFeatureFlags } from "src/contexts/global/FeatureFlagsContext";
import useTagsVisibleInFilter from "src/app/Restricted/useTagsVisibleInFilter";

import EnvInformationBar from "src/components/EnvInformationBar";
import GlobalNotification from "src/components/GlobalNotification";
import Search from "src/components/Search/SearchContainer";
import { isInternalSupportUser, DIRECTORY_ROLE } from "src/services/auth";
import { isLocalEnvironment } from "src/services/localSettings";
import useDefaultRoutePath from "src/app/Restricted/useDefaultRoutePath";
import Restricted from "src/app/Restricted";
import Logo from "../Logo";
import DirectoryLogo from "../Logo/DirectoryLogo";
import EmptyAppNav from "./EmptyAppNav";
import WorkspaceSwitcher from "./WorkspaceSwitcher";

const AppHomeLink = styled(StyledLink)`
  display: none !important;
  &:hover {
    text-decoration: none;
  }
  @media screen and (min-width: ${(props) =>
      themeGet("breakpoints.3")(props)}) {
    display: inline-block !important;
  }
`;

const FullAppNav = () => {
  const defaultRoutePath = useDefaultRoutePath();
  const { user } = useAuth0();
  const globalConfig = useGlobalConfig();
  const activeTenant = useActiveTenant();
  const auth = useAuth();
  const userPerson = useUserPerson();
  const workspaces = useWorkspaces();
  const workspace = useWorkspace();

  const featureFlags = useFeatureFlags();
  const tagsVisibleIn = useTagsVisibleInFilter();

  const { preferredUsername } = auth;

  const avatarSource = get(userPerson, "avatar");

  const isDemo = !!window?.location?.search?.includes("demo=true");

  const isSupportUser = isInternalSupportUser(user) || isLocalEnvironment();

  return (
    <>
      {activeTenant && (
        <EnvInformationBar
          user={user}
          overrideTenant={activeTenant}
          isDemo={isDemo}
        />
      )}
      <GlobalNotification />
      <HeaderSimple
        variant="search"
        logo={
          <Restricted allowedRoles={[DIRECTORY_ROLE]}>
            <DirectoryLogo workspace={workspace} />
          </Restricted>
        }
        appName={
          <Restricted
            fallback={
              <AppHomeLink
                aria-label="Go to directory home"
                bold
                white
                to={defaultRoutePath}
              >
                {globalConfig?.config?.appName}
              </AppHomeLink>
            }
          >
            <StyledLink block to={defaultRoutePath}>
              <Logo />
            </StyledLink>
          </Restricted>
        }
        currentWorkspace={
          <WorkspaceSwitcher
            workspaces={workspaces}
            isSupportUser={isSupportUser}
            withCustomStyles
          />
        }
        userName={
          <StyledLink white to={defaultRoutePath} id="user-home-link">
            {startCase(preferredUsername)}
          </StyledLink>
        }
        avatarSource={avatarSource}
        avatarAlt={`Avatar for ${startCase(preferredUsername)}`}
        searchComponent={
          <Search
            workspace={workspace}
            featureFlags={featureFlags}
            tagsVisibleIn={tagsVisibleIn}
            dropdownOptionsDisplayLimit={3}
          />
        }
      />
    </>
  );
};

// TODO: Duplication with code in the ErrorPageWrapper and AppNav
// because for some reason adding AppNav to ErrorPageWrapper causes circular
// dependency that was not quick to figure out.
const EmptyAppNavWithAuth = () => {
  const { logout, user } = useAuth0();
  return <EmptyAppNav user={user} logout={logout} />;
};

const AppNav = (props) => {
  const { emptyMode = false } = props;

  if (emptyMode) {
    return <EmptyAppNavWithAuth {...props} />;
  }

  return <FullAppNav {...props} />;
};

AppNav.propTypes = {
  emptyMode: PropTypes.bool,
};

export default AppNav;
