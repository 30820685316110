import { TAGS_UI_COMPONENTS } from "src/consts/tags";
import useUserRole from "src/app/Restricted/useUserRole";
import { DIRECTORY_ROLE } from "src/services/auth";

const useTagsVisibleInFilter = () => {
  return useUserRole() === DIRECTORY_ROLE
    ? TAGS_UI_COMPONENTS.DIRECTORY
    : TAGS_UI_COMPONENTS.TEAMFORM;
};

export default useTagsVisibleInFilter;
