import { filter } from "lodash";
import { useFeatureFlag } from "src/contexts/global/FeatureFlagsContext";

/**
 * This can be removed once the insights feature fully developed
 */
const useFilterNavItems = (navItems) => {
  const isInsightFlagEnabled = useFeatureFlag("strategy-tab-insights");

  if (!isInsightFlagEnabled) {
    return filter(navItems, (item) => item.name !== "Team alignment");
  }

  return navItems;
};

export default useFilterNavItems;
