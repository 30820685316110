export default {
  bold: ["far", "bold"],
  italic: ["far", "italic"],
  underline: ["far", "underline"],
  link: ["far", "link"],
  bulletList: ["far", "list-ul"],
  orderedList: ["far", "list-ol"],
  calendarDay: ["far", "calendar-day"],
  checkCircle: ["fas", "check-circle"],
  email: ["fas", "envelope"],
  mobile: ["fas", "mobile-alt"],
  microsoftTeams: ["fac", "microsoft-teams"],
  search: ["far", "search"],
  home: ["fas", "home"],
  // slack: ["fab", "slack"],
  breadcrumbDivider: ["fas", "chevron-right"],
  groupBreadcrumb: ["far", "layer-group"],
  breadcrumbAngle: ["far", "angle-right"],
  lock: ["fas", "lock"],
  pen: ["fas", "pencil"],
  plus: ["fas", "plus"],
  externalLink: ["far", "external-link"],
  exclamation: ["fas", "exclamation-circle"],
  exclamationTriangle: ["fas", "exclamation-triangle"],
  information: ["fas", "info"],
  infoCircle: ["fas", "info-circle"],
  invite: ["fas", "paper-plane"],

  // externalLink: ["fas", "external-link-alt"],
  save: ["fas", "save"],
  cancel: ["fas", "times"],
  copy: ["fas", "copy"],
  copied: ["fas", "clipboard-check"],
  // arrowUp: ["fas", "arrow-up"],
  // exclamationTriangle: ["fas", "exclamation-triangle"],
  // sitemap: ["fas", "sitemap"],
  // loading: ["fas", "circle-notch"],
  // comments: ["far", "comment-dots"],
  trash: ["far", "trash-alt"],
  back: ["far", "angle-left"],
  chevronUp: ["fas", "chevron-up"],
  chevronDown: ["fas", "chevron-down"],
  directions: {
    PROVIDER: ["fas", "long-arrow-alt-right"],
    CONSUMER: ["fas", "long-arrow-alt-left"],
  },
};
