import { useQuery } from "@apollo/client";
import { getValueGraphInsights } from "src/queries/valueGraphInsights.graphql";

const useInsightsData = ({ teamId, skip }) => {
  const { data, error, loading } = useQuery(getValueGraphInsights, {
    variables: {
      input: {
        groupIds: [teamId],
        filter: {
          includeDirectChildTeams: true,
        },
      },
    },
    skip,
  });

  return {
    loading,
    error,
    data: data?.getValueGraphInsights?.insights,
  };
};

export default useInsightsData;
