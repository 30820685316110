import { filter, find, get, isEmpty } from "lodash";
import debugModule from "debug";
import { OBJECTIVE_GROUP_TYPE } from "src/consts/objectives";
import { hasAdminOrPowerRole } from "src/services/auth";

const debug = debugModule("orchestrated:util:customerConfig");

export const customers = {
  phoenix: "phoenix",
  falcon: "falcon",
  default: "default",
};

export const findGroupTypes = (groupTypes, field) => {
  const matchingGroupTypes = filter(groupTypes, field);
  return matchingGroupTypes;
};

export const getRootType = (groupTypes) => {
  const rootTypes = findGroupTypes(groupTypes, "isRoot");
  if (rootTypes.length !== 1) {
    debug(`unable to find 1 root type, found: ${rootTypes}`);
    return null;
  }
  return rootTypes[0];
};

const getTypeForGroupType = (groupTypes, typeId) => {
  const groupType = find(groupTypes, (type) => type.id === typeId);
  return groupType;
};

export const getTypeForGroup = (groupTypes, group) => {
  return getTypeForGroupType(groupTypes, group.type);
};

export const canCreateChild = (groupTypes, group) => {
  const config = get(groupTypes, group.type, {});

  if (config.canCreateChild && !isEmpty(config.childTypes)) {
    return true;
  }

  return false;
};

const isSupply = (groupType) => {
  return (
    groupType.isSupply || groupType.isSupplyRoot || groupType.isSupplySource
  );
};

export const isDemand = (groupType) => {
  return groupType.isDemand;
};

export const getSupplyGroupTypes = (groupTypes) => {
  const supplyTypes = filter(groupTypes, isSupply);
  return supplyTypes;
};

export const getSupplyRoleGroupTypes = (groupTypes) => {
  const roleTypes = filter(
    groupTypes,
    (t) => t.isSupplyRoot || t.isSupplySource
  );

  return roleTypes;
};

export const getDemandGroupTypes = (groupTypes) => {
  const types = filter(groupTypes, isDemand);
  return types;
};

export const isSupplyOrDemand = (groupTypes, group) => {
  const groupType = getTypeForGroup(groupTypes, group);
  return groupType && (isSupply(groupType) || isDemand(groupType));
};

export const isLineOfBusiness = (groupTypes, group) => {
  const groupType = getTypeForGroup(groupTypes, group);
  return !!(groupType && groupType.isLineOfBusiness);
};

export const isSupplyGroup = (groupTypes, group) => {
  const groupType = getTypeForGroup(groupTypes, group);
  return !!(groupType && isSupply(groupType));
};

export const isDemandGroup = (groupTypes, group) => {
  const groupType = getTypeForGroup(groupTypes, group);
  return !!(groupType && isDemand(groupType));
};

export const getObjectiveGroupType = (groupTypes) => {
  return getTypeForGroupType(groupTypes, OBJECTIVE_GROUP_TYPE);
};

export const canRename = (user, groupTypes, group) => {
  if (!hasAdminOrPowerRole(user)) {
    return false;
  }

  const groupType = get(group, "type");

  if (!groupType) {
    return false;
  }

  const config = get(groupTypes, groupType);

  return get(config, "canRename", false);
};
