import { Select } from "orcs-design-system";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/client";
import { isEmpty, orderBy, map, filter } from "lodash";
import CustomSelect from "react-select";
import { useTheme } from "styled-components";
import { fallbackPath } from "src/consts/urlPaths";
import {
  useWorkspaceId,
  useSetWorkspaceId,
} from "src/contexts/global/ApolloSettingsContext";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import customStyles from "./customStyles";

const WorkspaceSwitcher = ({
  label,
  workspaces,
  isSupportUser,
  withCustomStyles = false,
}) => {
  const theme = useTheme();
  const client = useApolloClient();
  const history = useHistory();

  const selectedWorkspaceId = useWorkspaceId();
  const setSelectedWorkspaceId = useSetWorkspaceId();

  const workspaceOrderedOptions = useMemo(() => {
    return orderBy(
      map(workspaces, (workspace) => {
        let workspaceLabel = workspace?.name;
        if (workspace?.isActive) {
          workspaceLabel = `${workspaceLabel} (default)`;
        }

        if (isSupportUser) {
          workspaceLabel = `${workspaceLabel} (${workspace?.id})`;
        }

        return {
          label: workspaceLabel,
          ...workspace,
        };
      }),
      [(workspace) => workspace.name.toLowerCase(), "asc"]
    );
  }, [workspaces, isSupportUser]);

  const setWorkspace = useCallback(
    async (workspace) => {
      await client.clearStore();
      setSelectedWorkspaceId(workspace.id, workspace.isActive);
    },
    [client, setSelectedWorkspaceId]
  );

  const unsetWorkspace = useCallback(async () => {
    await client.clearStore();
    setSelectedWorkspaceId(null);
  }, [client, setSelectedWorkspaceId]);

  const onChange = useCallback(
    async (workspace) => {
      await unsetWorkspace();
      await setWorkspace(workspace);
      history.push(fallbackPath());
      history.go(0);
      trackEvent(EVENT_TRACKING.WORKSPACE_SWITCHED);
    },
    [setWorkspace, unsetWorkspace, history]
  );

  const selectedWorkspace = useMemo(
    () =>
      filter(
        workspaceOrderedOptions,
        ({ id, isActive }) =>
          id === selectedWorkspaceId || (!selectedWorkspaceId && isActive)
      ),
    [selectedWorkspaceId, workspaceOrderedOptions]
  );

  if (isEmpty(workspaces)) {
    return null;
  }

  return withCustomStyles ? (
    <CustomSelect
      aria-label="Select workspace"
      id="WorkspaceSelect"
      data-testid="select-workspace-dropdown"
      placeholder="Select a workspace"
      options={workspaceOrderedOptions}
      value={selectedWorkspace}
      onChange={onChange}
      height="30px"
      padding="0 8px"
      label={label}
      isOptionSelected={(option) => option.id === selectedWorkspaceId}
      styles={customStyles({ theme })}
      isSearchable={true}
    />
  ) : (
    <Select
      inputId="WorkspaceSelect"
      ariaLabel="Select workspace"
      data-testid="select-workspace-dropdown"
      placeholder="Select a workspace"
      options={workspaceOrderedOptions}
      value={selectedWorkspace}
      onChange={onChange}
      height="30px"
      padding="0 8px"
      label={label}
      isOptionSelected={(option) => option.id === selectedWorkspaceId}
    />
  );
};

WorkspaceSwitcher.propTypes = {
  label: PropTypes.string,
  workspaces: PropTypes.array,
  isSupportUser: PropTypes.bool,
  withCustomStyles: PropTypes.bool,
};

export default React.memo(WorkspaceSwitcher);
