import { get } from "lodash";
import { ALLOWED_ROLES } from "src/services/auth";
import { canPerform } from "src/components/Can";

const shouldShowReportsLink = ({
  workspace,
  isReportingInfraProvisioned,
  user,
  roles,
}) => {
  const reportsURL = get(workspace, "config.tenantConfig.reportsUrl");
  const canUserPerform = canPerform(user, roles, ALLOWED_ROLES.REPORTING);

  if (workspace?.isActive) {
    return !!(reportsURL && canUserPerform);
  }

  return !!(
    workspace?.isReportingEnabled &&
    isReportingInfraProvisioned &&
    reportsURL &&
    canUserPerform
  );
};

export default shouldShowReportsLink;
