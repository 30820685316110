import { findIndex, map } from "lodash";
import { Flex, Spacer } from "orcs-design-system";
import React from "react";
import {
  useInsightsData,
  useInsightsDispatch,
} from "src/contexts/valueGraphInsights";
import { ACTION } from "src/contexts/valueGraphInsights/strategyInsights/reducer/action";
import InsightPanel from "./components/InsightPanel";

const StrategyInsights = () => {
  const insightsData = useInsightsData();
  const dispatch = useInsightsDispatch();

  const handleClick = (type) => {
    const clonedTeams = [...insightsData];
    const selectedInsightIndex = findIndex(clonedTeams, { type });
    if (selectedInsightIndex === 0) {
      return;
    }
    const removedItem = clonedTeams.splice(selectedInsightIndex, 1);
    clonedTeams.unshift(removedItem[0]);
    dispatch({
      type: ACTION.SET_INSIGHTS_ORDER,
      payload: map(clonedTeams, "type"),
    });
  };

  return (
    <div id="keep-open-on-click-outside">
      Team alignment
      <Flex flexDirection="column">
        {map(insightsData, (insight, index) => {
          return (
            <Spacer my="s" key={index}>
              <InsightPanel handleClick={handleClick} insight={insight} />
            </Spacer>
          );
        })}
      </Flex>
    </div>
  );
};

export default React.memo(StrategyInsights);
